// vendors
import React from "react"
import { css } from "@emotion/core"
import { graphql } from "gatsby"

// components
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ContactCard from "../components/ContactCard"

// utils
import wrapper from "../utils/wrapper"
import regionLinkGenerator from "../utils/region-link-generator"
import mediaQuery from "../utils/media-query"
import { colors, fontSizes, fonts } from "../styles/variables"
import { useContactForm } from "../contexts/ContactForm"

const ContactPage = ({ data }) => {
  const { openGeneralForm } = useContactForm()

  const regions = (data.regions.nodes || []).map((region) => ({
    ...region,
    contact: {
      region: region.title,
      ...region.contact,
    },
  }))

  return (
    <Layout>
      <Seo
        description="Vous souhaitez postuler pour un stage d'externat ou un poste de médecin en région? Trouvez sur cette page toutes les informations pour nous contacter."
        lang="fr"
        keywords={[]}
        title="Nous contacter"
      />

      <article
        css={css`
          ${wrapper.bolt()}
        `}
      >
        <h1
          css={css`
            ${mediaQuery.greaterThen(1024)} {
              ${wrapper.paddingLeft()}
            }
            margin-bottom: ${fontSizes[9] / fontSizes[6]}em;
          `}
        >
          Coordonnées
        </h1>

        <ul
          css={css`
            list-style: none;
            margin: 0;
            padding: 0;

            display: flex;
            flex-direction: column;
            gap: 2rem;

            ${mediaQuery.greaterThen(1024)} {
              ${wrapper.paddingLeft()}
            }
          `}
        >
          {regions.map((region) => (
            <li>
              <ContactCard
                contact={region.contact}
                to={regionLinkGenerator(region.meta.slug.current || null)}
                small
              />
            </li>
          ))}

          <li
            css={css`
              background-color: ${colors.veryLightGreen};
              padding-top: ${80 / 19}em;
              padding-bottom: ${65 / 19}em;
              font-size: ${fontSizes[2] / fontSizes[0]}em;
              z-index: 0;
              position: relative;
              flex-grow: 1;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex-wrap: wrap;
              margin: 0 0 6.5em;

              ${mediaQuery.lessThen(768)} {
                ${wrapper.bolt("padding")}
                margin-top: -1.75em;
                margin-left: calc(50% - 50vw);
                margin-right: calc(50% - 50vw);
              }

              ${mediaQuery.greaterThen(768)} {
                margin-left: calc(-2em + 339px);
                padding-left: ${2 + fontSizes[8] / fontSizes[2]}em;
                padding-right: ${fontSizes[6] / fontSizes[2]}em;
                padding-top: 0;
                padding-bottom: 0;
                height: 333px;
              }
            `}
          >
            <div>
              <p
                css={css`
                  font-size: ${fontSizes[3] / fontSizes[2]}em;
                  line-height: ${fontSizes[4] / fontSizes[3]};
                  font-weight: bold;
                  margin: 0 0 ${fontSizes[2] / fontSizes[3]}em;
                  word-break: normal;
                  overflow: hidden;
                `}
              >
                Contact général
              </p>

              <address
                css={css`
                  font-family: ${fonts.accent};
                  line-height: ${fontSizes[3] / fontSizes[2]};
                  font-style: normal;
                  margin: 0;

                  /* These are technically the same, but use both */
                  overflow-wrap: break-word;
                  word-wrap: break-word;

                  -ms-word-break: break-all;
                  /* This is the dangerous one in WebKit, as it breaks things wherever */
                  word-break: break-all;
                  /* Instead use this non-standard one: */
                  word-break: break-word;
                `}
              >
                <button
                  css={css`
                    appearance: none;
                    border: none;
                    background: none;
                    color: ${colors.text};
                    padding: 0;
                    cursor: pointer;
                  `}
                  onClick={openGeneralForm}
                >
                  info@equipesarros.ca
                </button>
              </address>
            </div>
          </li>
        </ul>
      </article>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ContactPage {
    regions: allSanityRegion {
      nodes {
        meta {
          slug {
            current
          }
        }
        title
        contact {
          email
          extension
          name
          organisation
          phone
        }
      }
    }
  }
`
